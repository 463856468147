<template>
  <div class="partner-list">
    <div class="text-right">
      <CButton color="primary" to="/tfw">Back</CButton>
    </div>
    <CRow class="mt-2">
      <!-- <CCol md="4" v-for="(partner, index) of partners" :key="index">
        <CCard class="partner-card">
          <img class="img-partner" :src="partner.img" />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6 class="text-2-truncate slc-title px-1" :title="partner.name">
                {{ partner.name }}
              </h6>
            </div>
            <div class="partner-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                class="px-1 btn-profile"
                :to="`/tfw/partner/${partner.partner_id}/profile`"
                >View Profile</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol> -->
      <CCol
        md="4"
        v-for="(partnerPage, index) of partnersProfilePagesCustom"
        :key="index"
      >
        <CCard class="partner-card">
          <img class="img-partner" :src="partnerPage.img" />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6
                class="text-2-truncate slc-title px-1"
                :title="partnerPage.name"
              >
                {{ partnerPage.name }}
              </h6>
            </div>
            <div class="partner-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                class="px-1 btn-profile"
                :to="`/tfw/partner/${partnerPage.organisation_document_id}/view?html=${partnerPage.link_to_doc}`"
                >View Profile</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-if="partnersProfilePagesCustom.length===0" class="text-white h4 text-center mt-4">No Data Available</p>
  </div>
</template>
<script>
import { CONSTANT } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import { BASE_URL } from "@/service_urls";
export default {
  data() {
    return {
      partners: CONSTANT.partners,
    };
  },
  mounted() {
    this.initAction();
  },
  computed: {
    ...mapGetters(["partnersPagesWithOrg"]),
    partnersProfilePagesCustom() {
      const res = this.partnersPagesWithOrg.map((v, index) => {
        const partnerObj = v[0];
        const orgObj =v[1];
        return {
          ...partnerObj,
          name: orgObj.name,
          img: orgObj.logo_url ?`${BASE_URL}${orgObj.logo_url?.replace(
            "/api/v1",
            ""
          )}`: "/img/new_tf_logo.svg",
        };
      });
      return res;
    },
  },
  methods: {
    ...mapActions(["getPartnersPagesWithOrg"]),
    initAction() {
      this.getPartnersPagesWithOrg({
        all_rows: true,
        fetch_row_count: false,
        document_type_id: 62, // Profile page Document type id,
        order_by: "-organisation_document_id",
        deleted: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.partner-card {
  min-height: 250px;
}

.partner-footer {
  bottom: 5px;
}

.img-partner {
  height: 150px;
}
</style>
